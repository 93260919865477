import React from "react";
import "./Video.css";

import Heading from "../../Heading/Heading";
import Breadcrumbs from "../Aboutus/Breadcrumb/Breadcrumbs";
import SocialFooter from "../../SocialFooter/SocialFooter";
import ReactPlayer from "react-player";
// import vid from '../../Home-Page/Video/Aaryan.mp4';
import "aos/dist/aos.css";
import AOS from "aos"; // Import the modal component

const Video = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  // Fetching video
  const [vid, setVid] = React.useState([]);
  React.useEffect(() => {
    fetch("https://newlifeservices.in/video")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setVid(data);
      });
  }, []);

  return (
    <section>
      {" "}
      <Breadcrumbs
        heading="Video"
        backImage="https://images.unsplash.com/photo-1532718544028-9c2fe96569dc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className="container">
        <Heading headingLabel="video" />

        <div className="row">
          <div className="col-12">
            <p className="mb-3 mt-4" data-aos="fade-right">
              When you decide to join Aryan Devcon, you get to work with some of
              the best minds in the industry. Experienced and talented
              professionals form the backbone of our organization. The company
              offers exciting positions in management roles, for constructions
              personnel on site, opportunities in the Accounts, HR, Finance and
              the Administrative departments.
            </p>
          </div>

          <div className="col-12 " data-aos="fade-up">
            {/* <iframe
            className="videoss"
              src="https://www.youtube.com/embed/XN8jqE3otSo?autoplay=1&loop=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            {vid.map((item, index) => {
              return (
                <ReactPlayer
                  url={item.url}
                  playing={true}
                  width="90%"
                  height="90%"
                  loop={true}
                  controls={true}
                  volume={0}
                  pip={false}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              );
            })}
          </div>
        </div>
      </div>
      <SocialFooter />
    </section>
  );
};

export default Video;
