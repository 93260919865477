import React from 'react';
import Breadcrumbs from "./Breadcrumb/Breadcrumbs";
import Aboutmain from "./About-heading/Aboutmain";
import Mission from "./About-Mission/Mission";
import Team from "./Team/Team";
import Associates from "./Associates/Associates";
import SocialFooter from "../../SocialFooter/SocialFooter";
 


const About = () => {

  return (
    <section id="about-body">
      <Breadcrumbs
        heading="about"
        backImage="https://images.unsplash.com/photo-1535732759880-bbd5c7265e3f?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        
      />
      <Aboutmain />
      <Mission />
      <Team />
      <Associates />
      <SocialFooter/>
    </section>
  );
};

export default About;
