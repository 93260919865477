import React from "react";
import Heading from "../../../Heading/Heading";
import SocialFooter from "../../../SocialFooter/SocialFooter";
import test from "./Bunglowdata";
import BannerImg from "../../../BannerImg/BannerImg";
import "aos/dist/aos.css";
import AOS from "aos"; // Import the modal component

function Bunglow() {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  //Fetching Projects Data
  // const [formattedDate, setFormat] = React.useState();
  // const [project, setProject] = React.useState([]);
  // const [filter, setFilter] = React.useState([]);
  // React.useEffect(() => {
  //   fetch("https://newlifeservices.in/project")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProject(data);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   if (project.length > 0) {
  //     let temp = []
  //     project.map((item) => {
  //       if(item.category == "Bunglow"){
  //         temp.push(item);
  //       }
  //       setFilter(temp);
  //     });
  //   }
  //   console.log(filter);
  // }, [project]);

  return (
    <>
      <div className="commercial">
        <BannerImg src="https://cdn.pixabay.com/photo/2016/06/24/10/47/house-1477041_1280.jpg" />
        <div className="bg-head">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4" data-aos="fade-right">
               
                <Heading headingLabel="Bunglow Projects" />
              </div>
              <div
                className="col-12 col-md-8 heads_details"
                data-aos="fade-left"
              >
                Welcome to Aaryan Devcon Pvt. Ltd., where your dream home
                becomes a reality. Our expertise in designing and constructing
                bespoke bungalows ensures that every project is a testament to
                luxury, comfort, and superior craftsmanship.
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row projects_items">
            {test.map((item, index) => (
              <>
                <div
                  className="col-12 col-md-5 mt-4"
                  key={index}
                  data-aos="fade-right"
                >
                  <div className="projects_sample">
                    <img
                      src={item.imageSrc}
                      className="img-fluid"
                      alt="Project Sample"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-5 mt-4" data-aos="fade-left">
                  <h1>{item.projectName}</h1>
                  <p>{item.data}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <SocialFooter />
    </>
  );
}

export default Bunglow;
