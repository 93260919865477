import React, { useState  } from 'react'
import './prb.css'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io";
import MediaContentModal from './MediaContentModal';
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component
 
function Prb() {
  React.useEffect(() => {
    AOS.init({
      easing: 'ease',
    });
    AOS.refresh();
  }, []);
 
    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => setShowModal(false);
    const handleMediaContentClick = () => setShowModal(true);
  return (
    <>
         <div className='prb_started'>
      <div className='container prbs'>
        <div className='row'>
          <div className='col-12 tess'>
            <h6 className='text-light p-2' data-aos="fade-right">Quick Explore</h6>
            <ul className='prb_list p-2' data-aos="fade-right">
              <li><Link to='#' className='aaa'>Press Releases</Link></li>
              <li><IoIosArrowForward className='iconss' /></li>
              <li><Link to='#' className='aaa'>In the News</Link></li>
              <li><IoIosArrowForward className='iconss' /></li>
              <li><Link to='#' className='aaa' onClick={handleMediaContentClick}>Media Content</Link></li>
              <li><IoIosArrowForward className='iconss' /></li>
            </ul>
          </div>
        </div>
      </div>
      <MediaContentModal show={showModal} handleClose={handleModalClose} />
    </div>
    </>
  )
}

export default Prb