const herodata = [
{
    id : '1',
    image: './assest/hero/slider1.jpg',
    title: 'Your Dream Villa, Our Concrete Reality ',
    description: 'We sculpt your dream villa into an enduring reality, where every detail resonates with the essence of your aspirations.',
    link: '',

},
{
    id : '2',
    image: './assest/hero/slider2.jpg',
    title: 'Crafting Serenity, Building Dreams ',
    description: 'every home we build is a testament to your aspirations and our commitment to excellence, forging lasting impressions and cherished memories. ',
    link: '',

},

{
    id : '3',
    image: './assest/hero/slider3.jpg',
    title: 'Designing Elegance, Building Excellence',
    description: 'We believe in creating timeless masterpieces that resonate with sophistication and craftsmanship, setting new standards in luxury living and architectural innovation. ',
    link: '',

},

{
    id : '4',
    image: './assest/hero/slider4.jpg',
    title: 'Designing Dreams, Constructing Realities.',
    description: 'Our bespoke villas combine imagination, architectural mastery, luxury, and sustainability, creating timeless homes that exceed expectations. ',
    link: '',

},

{
    id : '5',
    image: './assest/hero/slider5.jpg',
    title: 'Inspired Living, Impeccable Craftsmanship ',
    description: 'Creating not just a living space, but an enduring masterpiece of architectural excellence and timeless beauty',
    link: '',

},





]

export default herodata;