import React from 'react'
import './Team.css'
import teamdata from './Teamdata'
import founder from './founder.jpeg'

const Team = () => {
  return (
    <section className='bg-team'>
    <div className='container'>
    <h2 className='mb-3' data-aos="fade-right">Senior Leadership Team</h2>
    <div className='row' data-aos="fade-right">

{
  teamdata.map(team => {
    return(
      <div className='col-12 col-md-3'>
      <div className='team-box'>
       <img src={founder} alt='team-img'/>
       <h4 className='mt-3'>{team.name}</h4>
       <p>{team.designation}</p>
       </div>
     
      </div>


    )
  }
  )
}

     
   
     
    </div>

    </div>
      
    </section>
  )
}

export default Team
