import React from "react";
import "./Quality.css";
import qualityWork from './qualityWork.png';

import Figure from "react-bootstrap/Figure";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Qualitywork = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);


  return (
    <section id="home-project">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 national-image" data-aos="fade-right">
            <img
              src={qualityWork}
              alt="img"
              className="image-fluid w-100"
            ></img>
          </div>

          <div className="col-12 col-md-6  quality" data-aos="fade-left">
            <h2>
              {" "}
              <span>Our Commitment to Uncompromising Quality</span>
            </h2>
            <div className="blue-line">
              <div className="yellow-line"></div>
            </div>
            <div className="row quality-sec">
              <div className="col-12 col-md-6 ">
                <div className="quality-border">
                  <Figure>
                    <Figure.Image
                      width={70}
                      height={70}
                      alt="171x180"
                      src="./assest/icon/home-icon-1.png"
                    />
                  </Figure>
                  <h4>Skilled Craftsmanship</h4>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div className="quality-border">
                  <Figure>
                    <Figure.Image
                      width={70}
                      height={80}
                      alt="171x180"
                      src="./assest/icon/home-icon-2.png"
                    />
                  </Figure>
                  <h4>Premium Materials</h4>
                </div>
              </div>
            </div>

            <p className="national-para">
              From the initial consultation to the final touches on your dream
              home, we prioritize craftsmanship, attention to detail, and
              customer satisfaction above all else. With years of expertise in
              the construction industry, we have honed our skills and refined
              our processes to ensure that every project we undertake exceeds
              expectations. When you choose us, you're choosing a partner
              dedicated to bringing your vision to life with unparalleled
              quality and professionalism.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualitywork;
