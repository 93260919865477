const associatesdata = [
  {
    id: "1",
    name: "Hafiz Contractor",
    desc: "Architects",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },
  {
    id: "1",
    name: "UAD Associates",
    desc: "Architects",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },
  {
    id: "1",
    name: "M/s Been Bensley",
    desc: "Architects",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },
  {
    id: "1",
    name: "Mr. Asit Vora & asso",
    desc: "RCC Consultant",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },

  {
    id: "1",
    name: "JW Consultants",
    desc: "RCC Consultant",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },

  {
    id: "1",
    name: "M/s. PHOENIX MILL LTD",
    desc: "Clients",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },

  {
    id: "1",
    name: "RPG Enterprises",
    desc: "Clients",
    para:
      "Architect Hafiz Contractor [AHC] is the leading architectural design consultancy firm, of India. The company has grown from a modest three-person firm in 1982 to having a team strength of over 550 plus people consisting of 24 Associates, 60+ Senior Architects, 90+ Architects, 5 SeniorProject Managers / Coordinators, 20+ Interior Designers, 10+ Site Engineers, 10 3D Visualizers & Photoshop Artists, 190+ Draughts men, 40+ Support / Technical staff and Student Interns.",
  },
];

export default associatesdata;
