import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
 
import pressdata from "../../Home-Page/Pressrelease/Pressdata";
import { IoIosArrowForward } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { FiYoutube } from "react-icons/fi";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component


function Singleblog() {
 
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);


  const { id } = useParams();
  // const blog  = pressdata[id];

  // Shivam code
  // const [blog,setBlog] = React.useState({});
  // useEffect(()=>{
  //   fetch(`https://newlifeservices.in/blog/${id}`)
  //   .then(res=>res.json())
  //   .then(data=>{
  //     setBlog(data);
  //   })
  // },[])

  //Fetching Press Release Data: GET /api/v1/blogs
  // const months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "Aug",
  //   "Sept",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  // function xyz(s){
  //   const d = new Date(s);
  //       let month = months[d.getMonth()];
  //       let year = d.getFullYear();
  //       let day = d.getDate();
  //       console.log(`${day} ${month}, ${year}`);
  //       // setFormat(`${day} ${month}, ${year}`);
  //       return `${day} ${month}, ${year}`; 
  // }

  return (
    <>
      <div>
        <div className="press_data">
          <div className="container">
            <div className="row">
              <div className="col-12" data-aos="fade-right">
                <p className="text-light mb-0">
                  <Link className="read_link text-light" to="/">
                    Home
                  </Link>
                  <IoIosArrowForward />
                  <Link className="read_link text-light" to="/press">
                    Press Releases
                  </Link>
                  <IoIosArrowForward /> 
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="press_details">
          <div className="container">
            <div className="row">
            
            {pressdata.map((item) => (
              <div className="col-12  " data-aos="fade-up">
              
              <div className="box_heading">
                <h1>{item.heading}</h1>
                <div className="social_icons_press pt-5">
                  <ul className="press_icon">
                    <li>
                      <Link to="https://www.instagram.com/aaryandevcon/">
                        <FaInstagram className="icon_press" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.facebook.com/people/Aaryan-Devcon-Pvt-Ltd/61559421080886/">
                        <CiFacebook className="icon_press" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/@AaryanDevconPvtLtd">
                        <FiYoutube className="icon_press" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blog_imgs" data-aos="fade-left">
                <img
                  src={item.image}
                  alt="Placeholder"
                  className="img-fluid"
                />
               
                
              </div>
            </div>


            ))}

 
  


          
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8"></div>
        </div>
      </div>
    </>
  );
}

export default Singleblog;
