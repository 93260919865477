  const test = [
        { 
            imageSrc:"https://images.unsplash.com/photo-1632152683081-28923830268d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            projectName: "Elegant Country Bungalow",
            data:" This bungalow features a spacious open-plan living area, high ceilings, and large windows that offer stunning views of the surrounding landscape. The design blends modern amenities with rustic charm, creating a warm and inviting home."
          },
          {
            
            imageSrc:"https://images.unsplash.com/photo-1635108201018-94bbbfac4a79?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            projectName: "Luxury Coastal Retreat",
            data:"Located on a beautiful stretch of coastline, this bungalow is designed for ultimate relaxation. With an expansive outdoor deck, infinity pool, and direct beach access, it’s the perfect getaway. The interior boasts premium finishes and state-of-the-art technology for a truly luxurious living experience."
          },
          {
          
          
            imageSrc:"https://images.unsplash.com/photo-1596178067639-5c6e68aea6dc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            projectName: "Modern Urban Bungalow",
            data:"Situated in a bustling urban neighborhood, this bungalow offers a stylish and contemporary living space. Innovative design maximizes space and natural light, while smart home features provide convenience and efficiency. It’s an oasis of calm amidst the city’s hustle and bustle."
          },
    

    ];
    export default test;