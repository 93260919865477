import React from 'react'
import "./BannerImg.css"
function BannerImg(props) {
  return (
    <div>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 m-0 p-0'>
                    <img src={props.src} className='img-fluid bb' alt='bannerimg'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannerImg