import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";

function Right() {
  const form = useRef();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm("service_vsq0jgi", "template_xzpfutn", form.current, {
        publicKey: "FCUJ4HkWI4GKkj17J",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  // Form Data
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendContact = async (e) => {


  await axios
  .post("https://newlifeservices.in/contact", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.alert(" Message Sent! ");
        console.log(res.data);
      });
    
  };

  return (
    <>
      <div className="col-12 col-md-6 right-contact" data-aos="fade-left">
        <div className="careerbox">
          <form
            className="row "
            ref={form}
            onSubmit={(e) => {
              
    e.preventDefault();
              sendEmail();
              sendContact();
            }}
          >
            <h3>Get in touch</h3>
            <div className="col-12">
              <label for="inputName" className="form-label  careerlabel">
                Name
              </label>
              <input
                type="Name"
                className="form-control careerform"
                id="inputName"
                placeholder="Enter First Name"
                name="from_name"
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
              />
            </div>

            <div className="col-12">
              <label for="inputEmail" className="form-label careerlabel ">
                Email
              </label>
              <input
                type="email"
                className="form-control careerform"
                id="inputEmail"
                placeholder="Enter Email"
                name="from_email"
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                }}
              />
            </div>

            <div className="col-12">
              <label for="inputPhone" className="form-label careerlabel">
                Phone
              </label>
              <input
                type="number"
                className="form-control careerform"
                id="inputPhone"
                placeholder="Enter Phone"
                name="from_phone"
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, phone: e.target.value };
                  });
                }}
              />
            </div>

            <div className="col-12">
              <div>
                <label for="floatingTextarea" className="careerlabel">
                  Comments
                </label>
                <textarea
                  className="form-control careerform contacttextarea"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="from_message"
                  onChange={(e) => {
                    setFormData((prev) => {
                      return { ...prev, message: e.target.value };
                    });
                  }}
                ></textarea>
              </div>
            </div>

            <div className="col-12">
              <button type="submit" className="sec-btn" value="Send">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Right;
