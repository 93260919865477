const projectdata =[

{
    id : '1',
    image: './assest/projects/awasalibaug.jpg',
    title: 'Awas Alibaug', 
    
},


{
    id : '1',
    image: './assest/projects/dhasaialibaug.jpg',
    title: 'Ahasau Alibaug ', 
    
},
{
    id : '1',
    image: './assest/projects/dhokawadealibaug.jpg',
    title: 'Dhokawade Alibaug ', 
    
},
{
    id : '1',
    image: './assest/projects/kihimalibaug.jpg',
    title: 'Kihim alibaug ', 
    
},
{
    id : '1',
    image: './assest/projects/koprolialibaug.jpg',
    title: 'Koproli Alibaug ', 
    
},


{
    id : '1',
    image: './assest/projects/panshetpune.jpg',
    title: 'Panshet Pune ', 
    
},

{
    id : '1',
    image: './assest/projects/rewasalibaug.jpg',
    title: 'Rewas Alibaug ', 
    
},






]


export default projectdata;