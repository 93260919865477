import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Leftform() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_vsq0jgi', 'template_bknu9pn', form.current, {
        publicKey: 'FCUJ4HkWI4GKkj17J',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <>
      <div className="col-12 col-md-7" data-aos="fade-right">
        <div className="careerbox">
          <form className="row" ref={form} onSubmit={sendEmail}> 
            <div className="col-12">
              <label htmlFor="inputName" className="form-label careerlbl">
                 Name
              </label>
              <input
                type="text"
                name="form_name"
                className="form-control careerform"
                id="inputName"
              />
            </div>

            <div className="col-12">
              <label htmlFor="inputEmail" className="form-label careerlbl">
                Email
              </label>
              <input
                type="email"
                name="form_email"
                className="form-control careerform"
                id="inputEmail"
              />
            </div>

            <div className="col-12">
              <label htmlFor="inputPhone" className="form-label careerlbl">
                Phone
              </label>
              <input
                type="number"
                name="form_phone"
                className="form-control careerform"
                id="inputPhone"
              />
            </div>

            <div className="col-12">
              <label htmlFor="inputState" className="form-label careerlbl">
                Position
              </label>
              <select
                name="form_position"
                id="inputState"
                className="form-select careerform"
              >
                <option value="" selected>
                  Choose...
                </option>
                <option value="Developer">Developer</option>
                <option value="Jr.Engg">Jr. Engg</option>
                <option value="Sr. Engg">Sr. Engg</option>
                <option value="Accountant">Accountant</option>
              </select>
            </div>

            <div className="col-12">
              <label htmlFor="inputZip" className="form-label careerlbl">
                Zip
              </label>
              <input
                type="text"
                name="form_zip"
                className="form-control careerform"
                id="inputZip"
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="inputGroupFile02" className="form-label careerlbl m-3">
                Upload Resume
              </label>
              <input
                type="file"
                name="form_resume"
                className="form-control careerform"
                id="inputGroupFile02"
              />
            </div>

            <div className="col-12">
              <button type="submit" className="sec-btn" value="Send">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Leftform;
