import ps2 from './bg-img/pressrelease2.jpeg';

const pressdata = [

    {
        id: '1',
        image: 'https://prestigegroup.me/wp-content/uploads/2024/01/10-tips-for-successfull-projects1-2048x1536.png',
        date: '1, April 2024',
        heading: 'Essential Tips for a Successful Construction Project',
        para: ' Embarking on a construction project can be both exciting and daunting. To ensure success and mitigate challenges, its crucial to have a well-thought-out plan in place. Here are five essential tips to help you navigate your construction project smoothly: ',
        link: '',
    },
    {
        id: '2',
        image: ps2,
        date: '18, April 2024',
        heading: 'Innovations Shaping the Future of Construction',
        para: ' The construction industry is undergoing a significant transformation thanks to advancements in technology and innovative practices. These innovations not only improve efficiency and productivity but also enhance safety and sustainability in construction projects. Here are some groundbreaking innovations that are shaping the future of construction: ',
        link: '',
    },
    {
        id: '3',
        image: 'https://images.unsplash.com/photo-1482731215275-a1f151646268?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        date: '30, April 2024',
        heading: 'Safety First: Aaryan Devcon Commitment to Workplace Safety',
        para: ' Aaryan Devcon Pvt. Ltd. stands as a beacon of excellence in the construction industry, not only for its remarkable projects but also for its unwavering dedication to ensuring the safety and well-being of its workforce. In this comprehensive blog post, we delve deep into the company`s robust safety culture, stringent protocols, and proactive measures that make safety the cornerstone of every operation. ',
        link: '',
    },




]

export default pressdata;