import React from 'react'
import './socialfooter.css'
import { ImFacebook2 } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa6";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

function SocialFooter() {

    React.useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease',
        });
        AOS.refresh();
      }, []);


  return (
    <>
    <section>
        <div className='social_footers'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 footer_social_title' data-aos='fade-right'>
                        <h4>Follow us on Social Media:</h4>
                    </div>
                    <div className='col-12 col-md-6 footer_social_icons'  data-aos='fade-left'>
                        <ul className='footer_list_icon'>
                            <li><Link to="https://www.facebook.com/profile.php?id=61559421080886" target='_blank'><ImFacebook2  className='footer_link_facebook'/></Link></li>
                            <li><Link to="https://www.instagram.com/aaryandevcon/" target='_blank'><AiFillInstagram className='footer_link_instagram'/></Link></li>
                            <li><Link to="https://www.youtube.com/@AaryanDevconPvtLtd" target='_blank'><IoLogoYoutube className='footer_link_youtube' /></Link></li>
                            <li><Link to="https://www.linkedin.com/company/102896480/admin/dashboard/" target='_blank'><FaLinkedin className='footer_link_linkedin' /></Link></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

export default SocialFooter