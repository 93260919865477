// images.js
const images = [
    { 
      name: "Image 1", 
      url: "./assest/gallery/01.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/02.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/03.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/04.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/05.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/06.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/07.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/08.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/09.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/10.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/11.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/12.jpg",
      
    },

    { 
      name: "Image 1", 
      url: "./assest/gallery/13.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/14.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/15.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/16.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/17.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/18.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/19.jpg",
      
    },
    { 
      name: "Image 1", 
      url: "./assest/gallery/20.jpg",
      
    },
   
  ];
  
  export default images;
  