import React, { useState } from "react";
import "./Countpage.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Experince = () => {
  const [counterState, setCounterState] = useState(false);

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <section >
      <div className="container-fluid one-counter-bg" >
        <div className="container one-head">
          <ScrollTrigger
            onEnter={() => setCounterState(true)}
            onExit={() => setCounterState(false)}
          >
            <div className="row  text-center count-section " data-aos="fade-up">
              <div className="col-12 col-md-3">
                <div className="count">
                  <img
                    src="./assest/icon/iconlocations-12.png"
                    alt="Icon"
                  ></img>
                  <h3>
                    {counterState && (
                      <CountUp start={0} end={200} duration={2.75} />
                    )}
                    +
                  </h3>
                  <p>Villas Built</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="count">
                  <img src="./assest/icon/satisfied.png" alt="Icon"></img>
                  <h3>
                    {counterState && (
                      <CountUp start={0} end={150} duration={2.75} />
                    )}
                    +
                  </h3>
                  <p>Satisfied Customers</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="count">
                  <img src="./assest/icon/ongoing.png" alt="Icon"></img>
                  <h3>
                    {counterState && (
                      <CountUp start={0} end={15} duration={2.75} />
                    )}
                    +
                  </h3>
                  <p>Ongoing Projects</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="count">
                  <img src="./assest/icon/locations.png" alt="Icon"></img>
                  <h3>
                    {counterState && (
                      <CountUp start={0} end={6} duration={2.75} />
                    )}
                    +
                  </h3>
                  <p>Cities Covered</p>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </section>
  );
};

export default Experince;
