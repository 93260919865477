import React from 'react';
import "./Contact.css";
import Iframe from "react-iframe";
import Breadcrumbs from "./../../Pages/Aboutus/Breadcrumb/Breadcrumbs";
import SocialFooter from "../../SocialFooter/SocialFooter";
import { SlUserFemale } from "react-icons/sl";
import Right from './Right';
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component
 
const Contact = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);
   
  const contactInfo = [
    { role: "HR", email: "hradmin@aaryandevcon.com" },
    { role: "Vendors", email: "collaborate@aaryandevcon.com" },
    { role: "Customers", email: "info@aaryandevcon.com" }
  ];
  return (
    <div>
      <Breadcrumbs
        heading="Contact"
        backImage="https://images.unsplash.com/photo-1587560699334-bea93391dcef?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6" >
            <div className="main_heading">
              <div className="contact_title" data-aos="fade-right">
                <h1>Contact Info</h1>
                <p className="mt-4" data-aos="fade-right">
                  Get in Touch with Aaryan Devcon Pvt. Ltd. We're thrilled that
                  you're interested in connecting with us. Whether you have
                  questions about our services, want to discuss a potential
                  project, or simply want to learn more about what we do, we're
                  here to help. we look forward to hearing from you and
                  exploring how we can collaborate to bring your construction
                  vision to life. At Aaryan Devcon Pvt. Ltd., we're dedicated to
                  building relationships as strong as the structures we create.
                  Get in touch with us today, and let's embark on a journey of
                  construction excellence together!
                </p>
              </div>
              <div className="contact_detailss">
                <div className="row border-cont">
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h4 className="cont-sec">call for help</h4>
                  </div>
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h5>+91 77700 11110</h5>
                  </div>
                </div>

                <div className="row border-cont">
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h4>Write Email</h4>
                  </div>
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h5>aaryaninfra@aaryandevcon.com</h5>
                  </div>
                </div>

                <div className="row border-cont">
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h4>Visit Office</h4>
                  </div>
                  <div className="col-12 col-md-6" data-aos="fade-right">
                    <h5>
                      Global 'A' Wing, Office No. 7/8, Opp. Hotel Big Splash,
                      Alibaug - 402 201 Dist. Raigad, Maharashtra
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Right/>
          {contactInfo.map((info, index) => (
        <div key={index} className="col-12 col-md-4 mt-4 d-flex justify-content-center align-items-center " data-aos="fade-up">
          <div className="contact_info_box">
            <div className="">
            <SlUserFemale  className="contact_icons"/>
            </div>
            <h2>{info.role} Enquiry</h2>
            <p>Email: {info.email}</p>
          </div>
        </div>
      ))}
        </div>
      </div>

      <div className="google-map">
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.275494751055!2d72.87161697465521!3d18.65162946515885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be87a3e31b1a739%3A0xc1daaa9d0630b74c!2sAARYAN%20DEVCON%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1713378245352!5m2!1sen!2sin"
          width="100%"
          height="450"
          
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></Iframe>
      </div>
      <SocialFooter />
    </div>
  );
};

export default Contact;
