import React from "react";
import Heading from "../../../Heading/Heading";
import "./Associates.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import associatesdata from "./Associatesdata";

const Associates = () => {
  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 80,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    // when window width is >= 768px
    1200: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
  };


  //Fetching Projects Data
  // const [associatesdata, setAssociatesdata] = React.useState([]);
  // React.useEffect(() => {

  //   fetch("https://newlifeservices.in/client")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setAssociatesdata(data);
  //     });

  // }, []);

  return (
    <section>
      <div className="container" data-aos="fade-up">
        <Heading headingLabel="Associates & Subsidiaries" />
        <p className="mt-4">
          Welcome to Aaryan Devcon Pvt. Ltd.'s Associates & Partners, where we
          highlight the valued relationships that drive our success in the
          construction industry. Collaborating with trusted associates and
          partners allows us to expand our capabilities, leverage expertise, and
          deliver exceptional results on every project. Here are some of the
          esteemed organizations we are proud to work with:
        </p>

        <div>
          <Swiper
            modules={[Autoplay]}
            breakpoints={breakpoints}
            autoplay={true}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
           
              
              {associatesdata.map((associates) => {
                return(
                  <SwiperSlide>
                  <div className="card-a">
                  <div className="head-associates">
                  <h3>{associates.name}</h3>
                  <h6>{associates.desc}</h6>
                  <p className="mt-3">
                    {associates.para}
                  </p>
                </div>
                </div>
                </SwiperSlide>
             
                );
              
              })}
             
          
 
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Associates;
