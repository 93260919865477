import React from "react";
import "./Breadcrumbs.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  return (
    <>
     
        <div
          className="breadcrumb_section"
          style={{backgroundImage:`url(${props.backImage})`}}>
          <div className="container">
            <div className="row ">
              <div className="col-12 bread_details">
                <h2>{props.heading}</h2>
                <div className="breadcrumb_list">
                  <p>
                  
                    <Link to="/" className="bread_links">
                      Home
                    </Link>
                    <MdKeyboardDoubleArrowRight  className="mid_icons"/>
                    <Link className="bread_links">{props.heading}
                    
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
    </>
  );
};

export default Breadcrumbs;
