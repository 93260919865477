import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route} from 'react-router-dom';
import './index.css';
import './media.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './Componet/Header/Header';
import Footer from './Componet/Footer/Footer';
import About from './Componet/Pages/Aboutus/About'
import Video from './Componet/Pages/Videos/Video';
import Career from './Componet/Pages/Career/Career';
import Blog from './Componet/Pages/Blog/Blog';
import Contact from './Componet/Pages/Contact/Contact';
import Commercial from './Componet/Pages/Projects/Commercial/Commercial';
import Residential from './Componet/Pages/Projects/Residential/Residential';
import Bunglow from './Componet/Pages/Projects/Bunglow/Bunglow';
import Singleblog from './Componet/Pages/Blog/Singleblog';
import Photos from './Componet/Pages/Photos/Photos';
import ScrollButton from './Componet/Header/ScrollButton';
import ScrollTop from './Componet/ScrollTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <BrowserRouter>
   <Header/>
   <ScrollTop/>
    <Routes> 
        <Route index path="/" element={<App/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="video" element={<Video/>}/>
        <Route path="commercial" element={<Commercial/>}/>
        <Route path="photo"  element={<Photos/>}/>
        <Route path="residential" element={<Residential/>}/>
        <Route path="bunglow" element={<Bunglow/>}/>
        <Route path="career" element={<Career/>}/>
        <Route path="press" element={<Blog/>}/>
        <Route path="press/:id" element={<Singleblog/>} />
        <Route path="contact" element={<Contact/>}/>
        <Route path="*" element={<App/>}/>
        </Routes>
    <Footer/>
    <ScrollButton/>
   </BrowserRouter> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
