import React, { useRef } from "react";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./Footer.css";
import Whatsappbutton from "../Whatsapp/Whatsappbutton";
import { FaInstagram } from "react-icons/fa6";
import { RiYoutubeLine } from "react-icons/ri";
import { AiOutlineFacebook } from "react-icons/ai";
import { GrLinkedinOption } from "react-icons/gr";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';

const Footer = () => {

  const form = useRef();
  const [to_mail,setTomail] = React.useState({
    to_mail:""
  });
  
  const publicKey = "q-N3bTkWKyOE1T7Rr";

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
      .send("service_m7ipfjl","template_2bld2rl",to_mail,publicKey)
        .then(
          function(){
            console.log('SUCCESS!');
          },
          function(error){
            console.log('FAILED...', error);
          },
        );
        e.target.value="";
    };


  return (
    <footer id="footer">
      <div className="footer-section">
        <div className="container">
          <div className="row main-footer my-3" style={{border: "none"}}>
            <div className="col-12 col-md-7 left-footer">
              <h2>
                <ContactMailIcon />
                <span className="signup">Sign Up to Get Latest News / Updates</span>
              </h2>
            </div>
            <div className="col-12 col-md-5 ">
              <form ref={form} onSubmit={sendEmail}>
                <div className="inner_form" >
                  <input
                    type="email"
                    class="form-control input-group-lg"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    onChange={(e)=>{
                      setTomail({...to_mail,to_mail: e.target.value});
                    }}  
                  />
                  <button type="submit" class="btn btn-primary footer-btn">
                  Send
                </button>
                </div>
                
              </form>
            </div>
          </div>
          <hr className="color"></hr>
          <div className="row aaryan-footer">
            <div className="col-12 col-md-12 col-lg-6">
              <h3>Global Presence Of Aaryan Devcon Projects</h3>
              <img
                src="/assest/Footer/TATA-MAP-1024x458-1.png"
                className="w-100"
                alt="test"
              ></img>
            </div>
            <div className=" col-12 col-md-6  col-lg-2">
              <h3>User-Links</h3>
              <ul className="mt-4 footer-ul">
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/commercial">
                    Projects
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/video">
                    Video
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/career">
                    Career
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="/contact">
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>

            <div className=" col-12 col-md-6 col-lg-4">
              <h3>Contact Us</h3>
              <ul className="mt-4 footer-ul">
                <li>
                  <KeyboardArrowRightIcon />
                  <Link
                    className="footer_links"
                    to="https://maps.app.goo.gl/nWV9a68bw2FHxNFM9"
                    target="_blank"
                  >
                    Global 'A' Wing, Office No. 7/8, Opp. Hotel Big Splash,
                    Alibaug - 402 201 Dist. Raigad, Maharashtra
                  </Link>
                </li>

                <li>
                  <KeyboardArrowRightIcon />
                  <Link className="footer_links" to="tel:917770011110">
                    +91 77700 11110
                  </Link>
                </li>

                <li>
                  <KeyboardArrowRightIcon />
                  <Link
                    className="footer_links"
                    to="mailto:aaryaninfra@aaryandevcon.com"
                  >
                    aaryaninfra@aaryandevcon.com
                  </Link>
                </li>
              </ul>

              <div className="social_footer">
                <Link to="https://www.instagram.com/aaryandevcon/" target='_blank'>
                  <FaInstagram className="s_icon" />
                </Link>
                <Link to="https://www.youtube.com/@AaryanDevconPvtLtd" target='_blank'>
                  <RiYoutubeLine className="s_icon" />
                </Link>
                <Link to="https://www.facebook.com/profile.php?id=61559421080886" target='_blank'>
                  <AiOutlineFacebook className="s_icon" />
                </Link>
                <Link to="https://www.linkedin.com/company/102896480/admin/dashboard/" target='_blank'>
                  <GrLinkedinOption className="s_icon" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <p className="last_line text-align-right">
                <Link className="last_link" to="/">
                  Privacy Policy
                </Link>{" "}
                |{" "}
                <Link className="last_link" to="/">
                  Cookie Policy.
                </Link>{" "}
              </p>
              <p className="last_line text-align-left">
                ©2024 Aaryan Devcon. All Rights Reserved.
                
             
              </p>
            </div>
          </div>
        </div>
      </div>
      <Whatsappbutton />
    </footer>
  );
};

export default Footer;
