import React from "react";
import "./Press.css";
import Heading from "../../Heading/Heading";
import pressData from "./Pressdata";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos"; // Import the modal component
// import pressdata from "./Pressdata";

const Pressrelease = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  //Fetching Press Release Data: GET /api/v1/blogs
  // const months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "Aug",
  //   "Sept",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  // const [formattedDate, setFormat] = React.useState();
  // const [pressData, setPressData] = React.useState([]);
  // React.useEffect(() => {
  //   fetch("https://newlifeservices.in/blog")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setPressData(data);
  //     });
  // }, []);

  // function xyz(s){
  //   const d = new Date(s);
  //       let month = months[d.getMonth()];
  //       let year = d.getFullYear();
  //       let day = d.getDate();
  //       console.log(`${day} ${month}, ${year}`);
  //       // setFormat(`${day} ${month}, ${year}`);
  //       return `${day} ${month}, ${year}`; 
  // }


  return (
    <section id="aaryan-devcon" className="mt-5 Pressrelese">
      <div className="container">
        <Heading headingLabel="Latest Press Release" data-aos="fade-right" />
        <div className="row press-section" data-aos="fade-left">
          {pressData.map((pressdata, index) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 main-press">
                <img src={pressdata.image} alt="img" />
                <p className="mt-3">{pressdata.date}</p>
                <h3 className="mt-3">{pressdata.heading}</h3>
                <p className="mt-3 press-para">{pressdata.para}</p>
                <Link to={`/press/${pressdata.heading}`} className="read_press">
                  Read more..
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Pressrelease;
