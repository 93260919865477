import React from "react";

import "./Blog.css";
import Other from "./Other";
import Breadcrumbs from "../Aboutus/Breadcrumb/Breadcrumbs";
import SocialFooter from "../../SocialFooter/SocialFooter";
import Prb from "../../Press_realses_banner/Prb";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Blog = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <Breadcrumbs
    heading="Press Releases"
    backImage="https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  />
  <Prb/>
  <Other />
    
    <SocialFooter/>
    </>
  );
};

export default Blog;
