import React from 'react';
import Heading from "../../Heading/Heading";
import "./Career.css";
import Breadcrumbs from "./../../Pages/Aboutus/Breadcrumb/Breadcrumbs";
import SocialFooter from "../../SocialFooter/SocialFooter";
import Leftform from './Leftform';import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component
 

const Career = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

   
  return (
    <div>
   <Breadcrumbs
    heading="Career"
    backImage="https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  />
      <div className="container">
        <Heading headingLabel="Career at aaryan-devcon"  />
        <div className="row">
          <p className="mt-4" data-aos="fade-right">
            When you decide to join Aryan Devcon, you get to work with some of
            the best minds in the industry. Experienced and talented
            professionals form the backbone of our organization. The company
            offers exciting positions in management roles, for constructions
            personnel on site, opportunities in the Accounts, HR, Finance and
            the Administrative departments.
          </p>
          <p data-aos="fade-right">
            At Aaryan Devcon, you are assured of learning opportunities and
            growth through diverse and challenging projects. The company backs
            up its staff with ample trainings and seminars to keep you abreast
            of the best industrial practices, offering a conducive environment
            for progress.
          </p>
          <p data-aos="fade-right">
            Aaryan Devcon is a pioneer which commenced its journey by building
            iconic villas in and around Alibaug and has spread its wing across
            the country. Come, join the progress.
          </p>
         
          <Leftform/>
          <div className="col-12 col-md-5" data-aos="fade-left">
            <img
              src="https://img.freepik.com/free-photo/fun-3d-cartoon-illustration-indian-businessman_183364-114475.jpg?t=st=1713161108~exp=1713164708~hmac=945f17a79d14ea62fdb8835a386431032a05fb32cd9534dece47545bd6918964&w=360"
              alt="imgss"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <SocialFooter/>
    </div>
    
  );
};

export default Career;
