import React, { useEffect, useState } from "react";
import "./Video.css";
import Heading from "../../Heading/Heading";
import ReactPlayer from "react-player";
 import vid from './Aaryan.mp4';
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Video = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  // Fetching video
  // const [vid, setVid] = useState([0]);
  //  useEffect(()=>{
  //    fetch("https://newlifeservices.in/video")
  //    .then(res=>{
  //      return res.json();
  //    })
  //    .then((data)=>{
  //     setVid(data[0].url);
  //      herodata.map(xyz=>console.log(xyz)) 
  //    })
  //  },[]);


  return (
    <section id="home-project">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 left" data-aos="fade-right">
            <Heading headingLabel="Video" />
            <h5 className="mt-3"> Discover Our Masterpieces</h5>
            <p className="mt-3">
              We invite you to explore our portfolio of exquisite projects
              brought to life. Dive into the world of unparalleled craftsmanship
              and architectural marvels as we showcase our latest ventures, from
              luxurious villas to serene Bungalows. Each video offers an
              immersive glimpse into our dedication to excellence, highlighting
              the meticulous attention to detail and innovative design solutions
              that define our work. Whether you're seeking inspiration for your
              own project or simply admiring the beauty of fine craftsmanship,
              our videos are sure to captivate and inspire. Join us on this
              visual journey and experience the essence of Aaryan Devcon's
              commitment to building dreams.
            </p>
            <button className="sec-btn">View More</button>
          </div>
          <div className="col-12 col-md-6 right" data-aos="fade-left">
            {/* <iframe
    style={{ width: '100%', height: '450px' }}
    src="https://www.youtube.com/embed/XN8jqE3otSo"
    title="Aaryan Devcon"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe> */}
          <ReactPlayer
           url= {vid}
           playing={true}
           width='100%'
           height='100%'
           loop = {true}
           controls = {true}
           volume={0}
           pip={false}
           config={{ file: { attributes: { controlsList: 'nodownload' } } }}
           onContextMenu={e => e.preventDefault()}
          />

          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
