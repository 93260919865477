import React from 'react'

import { FaFacebookF } from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { FaPhone } from "react-icons/fa6";
import './Top.css'
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Topnav = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);


  return (
   <>
   {/* <div className='container-fluid top-color'>
    <div className='top-nav'>
    <div className='row top-bar'>
        <div className='col-12 col-md-8'>
      <ul className='top-list'>
        <li className='top-icon'> <EmailIcon/>
        <span>admin@gmail.com</span></li>
        <li className='top-icon'> <CallIcon/>
        <span>+91 7770011110  </span></li>
        <li className='top-icon'> <AddLocationAltIcon/>
        <span>20 Brooklyn Street, New York. USA</span></li>
        
      </ul>
            
        </div>
        <div className='col-12 col-md-4 '>
        <button className='btn-top'>Get a Free Quote</button>
        </div>
        </div>
       
        </div>
    </div> */}
    <div className='topnavsection'>
      <div className='container'>
        <div className='row'>
          <div className='col-6 col-md-8 '>
          <ul className='icon_lists'>
              <li><Link to="https://maps.app.goo.gl/WTJmHHABhZU6ztSaA" target='_blank' className='icon_link'><FaLocationDot /></Link></li>
              <li><Link to="mailto:AARYANINFRA@GMAIL.COM" target='_blank' className='icon_link'><BiLogoGmail /></Link></li>
              <li><Link to="tel:+917770011110" className='icon_link'><FaPhone /></Link></li>
          </ul>
          </div>
          <div className='col-6 col-md-4'>
            <ul className='icon_list'>
              <li><Link to="https://www.facebook.com/profile.php?id=61559421080886" target='_blank' className='icon_link'><FaFacebookF /></Link></li>
              <li><Link to="https://www.instagram.com/aaryandevcon/" target='_blank' className='icon_link'><BiLogoInstagramAlt /></Link></li>
              <li><Link to="https://www.youtube.com/@AaryanDevconPvtLtd" target='_blank' className='icon_link'><IoLogoYoutube /></Link></li>
              <li><Link to="https://www.linkedin.com/company/102896480/admin/dashboard/" target='_blank' className='icon_link'><FaLinkedin /></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
   
   </>
  )
}

export default Topnav
