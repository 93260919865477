const datas = [

        {

          imageSrc:
            "https://images.unsplash.com/photo-1702390849292-7848ee97970e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Serenity Heights",
          data:"Serenity Heights offers a retreat from the hustle and bustle of city life. Each villa is meticulously designed to harmonize with nature while providing modern comforts. Enjoy breathtaking views, lush greenery, and a serene ambiance that rejuvenates your soul."
        },

        {

          imageSrc:
            "https://images.unsplash.com/photo-1640113036738-02909e67ff61?q=80&w=1926&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Elysian Gardens",
          data:"Welcome to Elysian Gardens, where luxury meets elegance. This exclusive gated community offers an unparalleled lifestyle experience. Immerse yourself in the opulence of spacious bungalows, manicured lawns, and world-class amenities designed to elevate your living standards. "
        },
        {

          imageSrc:
            "https://images.unsplash.com/photo-1651313948751-7dc310593733?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Tranquil Enclave",
          data:"Experience the epitome of refined living at Tranquil Enclave, where every detail is crafted to perfection. This premium residential project exudes sophistication and charm. Indulge in the grandeur of majestic villas, lush landscapes, and a serene environment that inspires a sense of tranquility."
        },

  ];

  export default datas;