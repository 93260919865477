const teamdata =[
{
    id: '',
    image: '',
    name: 'Mr. Sanjay Chavan',
    designation: 'Founder',

},






]

export default teamdata;