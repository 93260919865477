import React from 'react';
import Hero from './Hero-section/Hero';
import Builder from './Builder/Builder';
import Project from './Project-slider/Project';
import Experince from './Pagecount/Exprince';
import Qualitywork from './Quality-work/Qualitywork';
import Onecontent from './One-content-single/Onecontent';
import Video from './Video/Video';
import Pressrelaese from './Pressrelease/Pressrelease';
import Faq from './Faq/Faq';
import SocialFooter from '../SocialFooter/SocialFooter';
import ScrollButton from '../Header/ScrollButton';


const Home = () => {
  return (
    <div>
      <Hero/>
      <Builder/>
      <Project/>
      <Onecontent/>
      <Qualitywork/>
      <Experince/>
      <Video/>
      <Pressrelaese/>
      <Faq/>
      <SocialFooter/>
      <ScrollButton/>
    </div>
  )
}

export default Home
