import React from "react";
import "./Builder.css";
import "aos/dist/aos.css";
import AOS from "aos"; // Import the modal component
import { Link } from "react-router-dom";

const Builder = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  return (
    <section id="home-content" className="builder">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 national-image" data-aos="fade-right">
            <img
              src="https://firma-pro.com/media/magefan_blog/_28A2140_katownica_start13.png"
              alt="img"
              className="image-fluid w-100"
            ></img>
          </div>
          <div className="col-12 col-md-6  national-image" data-aos="fade-left">
            <h1 className="heading-text ">
              {" "}
              <span> Crafting dream , Building Futures</span>
            </h1>
            <div className="blue-line">
              <div className="yellow-line"></div>
            </div>
            <p className="national-para">
              We believe in more than just constructing structures; we are
              architects of aspirations and builders of destinies. With each
              villa and Bungalows we meticulously design and build, we are not
              only creating spaces, but we are also shaping futures. Our
              commitment lies in crafting homes that fulfill dreams and inspire
              generations. From the initial blueprint to the final touches, we
              pour our passion and expertise into every detail, ensuring that
              each project is a testament to quality, innovation, and the
              realization of aspirations. At Aaryan Devcon Pvt. Ltd., we're not
              just building houses; we're shaping communities and laying the
              foundation for generations to come.
            </p>
            <Link to="/about">
              <button className="sec-btn">Read More..</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Builder;
