import React, { useState, useEffect } from 'react';
import './Project.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import projectdata from './Projectdata';
import Card from 'react-bootstrap/Card';
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Project = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);


  const options = {
    loop: true,
    nav: true,
    lazyLoad: true,
    center: true,
    margin: 10,
    pause:'hover',
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
    autoplay: true, // Add autoplay configuration
  };


  return (
    <section id='aaryan-devcon' className='project-data'>
      <div className='container' >
        <div className='row' data-aos="fade-right">
          <div className='col-12'>
            <h2 className='main-heading'>Landmark Projects</h2>
            <div className='blue-line'>
              <div className='yellow-line'></div>
            </div>
          </div>
        </div>
        <OwlCarousel className='owl-theme' {...options}  data-aos="fade-right">
          {/* ----------API DATA FETCH-------------- */
          projectdata.map((project, index) => (
            <div key={index}>
              <Card className='custom-card'>
                <Card.Img variant='top' src={project.image} />
                <Card.Body>
                  <Card.Title className='text-size'>
                    {project.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          ))

          }
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Project;
