import React from 'react';
import Breadcrumbs from "../Aboutus/Breadcrumb/Breadcrumbs";
import "./photos.css";
import Heading from "../../Heading/Heading";
import images from "./images"; // Import the array of image URLs
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component
 
function Photos() {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);
   
  return (
    <>
      <div>
        <Breadcrumbs
          heading="Photos"
          backImage="https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>

      <div>
        <div className="container " data-aos="fade-right">
        <Heading headingLabel="Photos"  />
          <div className="row mt-3">
          <p className="mb-3" data-aos="fade-right">
            When you decide to join Aryan Devcon, you get to work with some of
            the best minds in the industry. Experienced and talented
            professionals form the backbone of our organization. The company
            offers exciting positions in management roles, for constructions
            personnel on site, opportunities in the Accounts, HR, Finance and
            the Administrative departments.
          </p>
            {images.map((image, index) => (
              <div key={index} className="col-12 col-md-4 photos" data-aos="fade-up">
                <img src={image.url} alt={image.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Photos;
