import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Heading from "../../Heading/Heading";
import "./Faq.css";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Faq = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <section id="home-content">
      <div className="container" data-aos="fade-up">
        <Heading headingLabel="frequently asked questions"  />
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              What types of construction projects does Aaryan Devcon Pvt. Ltd.
              undertake?
            </Accordion.Header>
            <Accordion.Body>
              Aaryan Devcon Pvt. Ltd. specializes in a wide range of
              construction projects, including residential villas, farmhouses,
              commercial buildings, and architectural renovations. Whether
              you're looking to build your dream home or develop a commercial
              property, we have the expertise to bring your vision to life.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How do I get started with a construction project with Aaryan
              Devcon Pvt. Ltd.?
            </Accordion.Header>
            <Accordion.Body>
              Getting started is easy! Simply contact us through our website or
              give us a call to schedule an initial consultation. During this
              meeting, we'll discuss your project requirements, budget, and
              timeline, and outline the next steps to begin the construction
              process.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How long does a typical construction project take to complete?
            </Accordion.Header>
            <Accordion.Body>
              The duration of a construction project can vary depending on
              factors such as project size, complexity, and weather conditions.
              During the initial consultation, we'll provide you with a detailed
              timeline based on your specific project requirements, allowing you
              to plan accordingly.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What sets Aaryan Devcon Pvt. Ltd. apart from other construction
              companies?
            </Accordion.Header>
            <Accordion.Body>
              At Aaryan Devcon Pvt. Ltd., we pride ourselves on our commitment
              to excellence, quality craftsmanship, and customer satisfaction.
              Our team of skilled professionals brings years of experience and
              expertise to every project, ensuring that we deliver superior
              results that exceed our clients' expectations.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What safety measures does Aaryan Devcon Pvt. Ltd. implement on
              construction sites?
            </Accordion.Header>
            <Accordion.Body>
              Safety is our top priority on every construction site. We adhere
              to strict safety protocols and regulations to ensure the
              well-being of our team members, subcontractors, and clients. Our
              comprehensive safety measures include regular inspections, safety
              training, and the use of protective equipment.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};

export default Faq;
