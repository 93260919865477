import React, { useEffect, useState } from 'react'; // Make sure useState is imported
import Home from './Componet/Home-Page/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollTop from './Componet/ScrollTop';
import "aos/dist/aos.css";
import AOS from 'aos';
import './App.css';

import Loadder from './Componet/Pages/Loadder/Loadder';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Adjust the timeout duration as needed
  }, []);
 
  return (
    <>
      {isLoading ? (
        <Loadder />
      ) : (
        <>
          <ScrollTop />
          <Home />
        </>
      )}
    </>
  );
}

export default App;
