const sample = [
        {
          imageSrc:
            "https://images.unsplash.com/photo-1565008447742-97f6f38c985c?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Corporate Plaza",
          data:"A masterpiece of modern architecture, our Corporate Plaza stands tall as a symbol of sophistication and professionalism. Located in the heart of the city, this landmark building offers premium office spaces designed to inspire productivity and foster success. With state-of-the-art amenities and sustainable features, Corporate Plaza is the perfect address for thriving businesses"
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Business Park",
          data:"Experience the perfect blend of work and leisure at Business Park, where innovation meets tranquility. Set amidst lush greenery and scenic surroundings, this eco-friendly commercial enclave offers a refreshing escape from the urban hustle. With sustainable design principles and premium amenities, Business Park fosters a conducive environment for productivity and well-being."
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1497366754035-f200968a6e72?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          projectName: "Business Square",
          data:"Business Square is more than just a commercial development—it's a landmark destination that elevates the business landscape. Featuring sleek and sophisticated design elements, this prestigious complex offers premium office spaces, upscale retail outlets, and upscale dining options. With its iconic presence and unparalleled amenities, Business Square epitomizes sophistication and style in the heart of the city."
        },
  
  ];
  export default sample;