import React from 'react';
import pressdata from "../../Home-Page/Pressrelease/Pressdata"
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component
 
function Other() {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);
 
  // Blog section API CALL
  // const [pressdata,setPressdata] = React.useState([]);
  // React.useEffect(()=>{
  //   fetch("https://newlifeservices.in/blog")
  //   .then(res=>res.json())
  //   .then(data=>{
  //     setPressdata(data);
  //   })
  // },[])

  return (
    <>
      <div className="search_area">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 p-0 mt-0">
              <div className="search_box">
                <div className="row p-5">
                  <div className="col-12 col-md-2 pt-2">
                    <p>Select Years</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <select
                      class="form-select form-select-lg "
                      aria-label="Default select example"
                    >
                      <option selected>Years</option>
                      <option value="1">2023</option>
                      <option value="2">2022</option>
                      <option value="3">2021</option>
                    </select>
                  </div>
                
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="other_blogsection">
        <div className="container">
          <div className="row ">
            {pressdata.map((item, ) => (
              <div className="col-12 press_heads mb-2" data-aos="fade-up">
                <p>
                  <IoIosArrowForward />
                  <Link className=" read_link" to={`/press/${pressdata.id}`}>
                  
                    {item.heading}
                  </Link>
                </p>
              </div>
            ))}

            {/* {data.map((item, index) => (
                <div className='col-12 col-md-6 col-lg-4 mt-4' key={index}>
                <div className='card_box'>
                    <div className='card_image'>
                    <img src={item.imageUrl} alt='Placeholder' className='img-fluid'/>
                    </div>
                    <div className='card_titles'>
                    <p>{item.heading}</p>
                    <p className='text-end'> <Link className='read_link' to={`/blog/${index}`}> Read More <IoIosArrowForward /></Link></p>
                    </div>
                </div>
                </div>
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Other;
