import React from "react";
import "./Onecontent.css";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Onecontent = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  const navigate = useNavigate();

  return ( 
    <>
 <section id="onecontent" className="single-content">
      <div className="container">
        <div className="row single-con" data-aos="fade-right">
          <div className="col-12 col-md-6 ">
            <h2>Let's build your dream together</h2>
          </div>
          <div className="col-12 col-md-3 ">
            <button className="git" onClick={()=>{
              navigate('/contact');
            }}>GET In Touch</button>
          </div>
        </div>
      </div>
    </section>

    
    </>
   

  );
};

export default Onecontent;
