import React from "react";
import "./Aboutmain.css";import 'aos/dist/aos.css';
import AOS from 'aos';

const Aboutmain = () => {
  AOS.init();
  AOS.refresh();
  return (
    <section>
      <div className="container">
        <div className="about-main-head" >
          <h3 data-aos="fade-right">About us</h3>
          <p data-aos="fade-right">
            Discover the allure of handcrafted homes with Aryan Devcon, where
            each residence is a testament to inspired living and impeccable
            craftsmanship. Nestled in picturesque landscapes like Alibaug, our
            sprawling villas and iconic bungalows epitomize the essence of
            luxury living.
            <p/>
            <p>
              At the helm of Aryan Devcon is Mr. Sanjay Chavan, a dynamic
              visionary whose passion for excellence propels our endeavors
              forward. With a background in Civil Engineering and a wealth of
              experience, Mr. Chavan orchestrates a symphony of expertise,
              collaborating with esteemed architects and consultants to bring
              every vision to fruition. Behind the scenes, a dedicated team of
              engineers and professionals ensures that each project is executed
              with precision, adhering to our steadfast commitment to quality
              and timely delivery. For over three decades, Aryan Devcon has been
              synonymous with exclusivity, crafting dream bungalows in Alibaug
              and beyond, alongside prestigious residential, commercial, and
              industrial ventures. Our clientele comprises discerning
              individuals from various walks of life, including renowned
              celebrities, esteemed doctors, visionary industrialists, and
              enterprising entrepreneurs.
            </p>
            <p>
              From the majestic hills of Lonavala to the sun-kissed shores of
              Goa, our portfolio extends across prime locales, including
              Alibaug, Lonavala, Khandala, Pune, Nagpur, and Karjat. With each
              project, we embark on a journey of trust and collaboration,
              embodying our ethos of diligence, integrity, and unwavering
              commitment to customer satisfaction. Beyond borders, our footprint
              extends to foreign shores, where we assist our esteemed clientele
              in realizing their aspirations of luxury living in destinations
              like Dubai and the UAE. Aryan Devcon - Where craftsmanship meets
              commitment, and dreams find their abode. Join us on a journey of
              excellence, where every brick is a testament to our dedication to
              perfection.
            </p>
            <p>
              We believe in more than just constructing structures; we are
              architects of aspirations and builders of destinies. With each
              villa and Bungalows we meticulously design and build, we are not
              only creating spaces, but we are also shaping futures. Our
              commitment lies in crafting homes that fulfill dreams and inspire
              generations. From the initial blueprint to the final touches, we
              pour our passion and expertise into every detail, ensuring that
              each project is a testament to quality, innovation, and the
              realization of aspirations. At Aaryan Devcon Pvt. Ltd., we're not
              just building houses; we're shaping communities and laying the
              foundation for generations to come.
            </p>
           
          </p>
        </div>
      </div>
    </section>
  );
};

export default Aboutmain;
