import React, {  } from 'react';
import Heading from "../../../Heading/Heading";
import "./Commercial.css";
 
import SocialFooter from "../../../SocialFooter/SocialFooter";
import sample from "./Commercialdata";
import BannerImg from "../../../BannerImg/BannerImg";
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component


function Commercial() {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  //Fetching Projects Data
  // const [project, setProject] = React.useState([]);
  // const [filter, setFilter] = React.useState([]);
  // React.useEffect(() => {
  //   fetch("https://newlifeservices.in/project")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProject(data);
  //     });
  //     console.log(project);
  // }, []);

  // React.useEffect(() => {
  //   if (project.length > 0) {
  //     let temp = []
  //     project.map((item) => {
  //       if(item.category == "Commercial"){
  //         temp.push(item);
  //       }
  //       setFilter(temp);
  //     });
  //   }
  //   console.log(filter);
  // }, [project]);
   
  return (
    <>
      <div className="commercial">
        <BannerImg src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />

        <div className="bg-head">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4" data-aos="fade-right">
                
                <Heading headingLabel="Commercial Projects" />
              </div>
              <div className="col-12 col-md-8 heads_details" data-aos="fade-left">
                At Aaryan Devcon Pvt. Ltd., we bring your commercial aspirations
                to life with our impeccable craftsmanship, innovative designs,
                and unwavering commitment to quality. Explore our portfolio of
                commercial projects that redefine excellence and set new
                standards in the industry.
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row projects_items"> 
            {sample.map((item, index) => (
              <>
                <div className="col-12 col-md-5 mt-4" key={index} data-aos="fade-right">
                  <div className="projects_sample">
                    <img
                      src={item.imageSrc}
                      className="img-fluid"
                      alt="Project Sample"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-5 mt-4" data-aos="fade-left">
                  <h1>{item.projectName}</h1>
                  <p>{item.data}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <SocialFooter />
    </>
  );
}

export default Commercial;
