import React from 'react';
 
import "./Heading.css";

import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component



const Heading = ({ headingLabel }) => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <div className="row">
      <div className="col-12" data-aos="fade-right">
        <h2 className="main-heading">{headingLabel}</h2>
        <div className="heading-blue-line">
          <div className="heading-yellow-line"></div>
        </div>
      </div>
    </div>
  );
};

export default Heading;
