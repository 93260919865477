import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './Hero.css'
import herodata from './Herodata';
import '../../../media.css'; 
import 'aos/dist/aos.css';
import AOS from 'aos'; // Import the modal component

const Hero = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

   // Fetching slider
  //  const [herodata, setHeroData] = useState([0]);
  //  useEffect(()=>{
  //    fetch("https://newlifeservices.in/slider")
  //    .then(res=>{
  //      return res.json();
  //    })
  //    .then((data)=>{
  //      setHeroData(data);
  //     //  herodata.map(xyz=>console.log(xyz)) 
  //    })
  //  },[]);
   //------------------------------
 


  return (
    <section id='hero' className='hero-block'>
     <Carousel fade interval={3000}>
{
    herodata.map(hero => {
        return(
            <Carousel.Item >
        <img className='d-block w-100'src={hero.image}alt="image"/>
        <Carousel.Caption data-aos="fade-right">
          <h1 >{hero.title} </h1>
          <p className='text-justify'>{hero.description}</p>
        </Carousel.Caption>
      </Carousel.Item>

        );
    })
    }


     
      
    </Carousel>
    </section>
  )
}

export default Hero
