import React, { useState, useEffect } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../logo.jpg"

import Topnav from "../Top-nav/Topnav";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Topnav />
      <Navbar
        expand="lg"
        className={`header justify-content-between ${
          isScrolled ? "fixed-top" : ""
        }`}
      >
        <Container>
          <Link to="/" className="logo brand_logo">
            <img src={logo} width="100px"  height="50px" alt="Logo" />
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto lists custom-nav">
              <Link to="/about" className="nav-link border_bottom">
                We Are
              </Link>
              <NavDropdown
                title="Landmark Project"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdown.Item as={Link} to="/commercial">
                  Commercial Projects
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/residential">
                  Residential Projects
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/bunglow">
                  Bungalow Projects
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Gallery"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdown.Item as={Link} to="/photo">
                  Photos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/video">
                  Video
                </NavDropdown.Item>
              </NavDropdown>
              <Link to="/career" className="nav-link">
                CAREER
              </Link>
              <Link to="/press" className="nav-link">
                Press Releases
              </Link>
              <Link to="/contact" className="nav-link">
                CONTACT US
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
