import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Mission.css";

const Mission = () => {
  return (
    <section>
      <div className="container">
        <div className="mission-vission">
          <h2 data-aos="fade-left">A FEW WORD ABOUT US</h2>
        </div>

        <div className="row vission" data-aos="fade-right">
          <div className="col-12 col-md-4">
            <h4>Skill We Have?</h4>
            <div>
              <h6 className="mt-5">Client Satisfaction: 100%</h6>
              <ProgressBar animated now={100} />
              <h6>Quality Assurance: 98%</h6>
              <ProgressBar animated now={98} />
              <h6>Project Management: 95%</h6>
              <ProgressBar animated now={95} />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <h4>Mission</h4>
            <p className="mt-5">
              Our mission is to expand our footprint across varied markets and
              propel the company to new summits through the punctual execution
              of projects.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <h4>Vission</h4>
            <p className="mt-5">
              Our vision is to become a premier and reputable entity in the
              realm of real estate construction, crafting sustainable residences
              while upholding the highest standards of construction excellence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
